export const validateDayOfMonth = (
  day: string,
  month: string,
  year: string
): string => {
  if (!day) return '';
  if (!month) return day;
  if (parseInt(month) === 2) {
    const isBisectYear =
      !year || parseInt(year) % 100 === 0
        ? parseInt(year) % 400 === 0
        : parseInt(year) % 4 === 0;
    if (isBisectYear) {
      return parseInt(day) > 29 ? '29' : day;
    } else {
      return parseInt(day) > 28 ? '28' : day;
    }
  }
  if ([4, 6, 9, 11].includes(parseInt(month))) {
    return parseInt(day) > 30 ? '30' : day;
  }

  return parseInt(day) > 31 ? '31' : day;
};
