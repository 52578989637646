import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { useSpring } from 'react-spring';

import { getLocation, getLocationPath } from 'src/actions/countryActions';
import QuestionMarkIconCircle from 'src/assets/QuestionMarkIconCircle';
import DayMonthYearFilter, {
  monthOptions,
} from 'src/filters/day-month-year-filter';

import type {
  ListRegionStatesTypes,
  LocationsTypes,
  PathTypes,
} from 'src/types/edit-create-types';
import type { DataWorldChartTypes } from 'src/types/types';
import moveCountryToFirstPlace from 'src/utils/moveRefCountryToFirstPlace';
import TailwindAlert from '../alert/tailwind-alert';
import ReactTooltip from '../react-tooltip/ReactTooltip';
import TailwindSelect from '../select/select';

interface BirthDetailsProps {
  countries: DataWorldChartTypes[] | LocationsTypes[] | [];
  handleBirthDay: (value: ChangeEvent<HTMLInputElement>) => void;
  handleBirthMonth: (value: string) => void;
  handleBirthYear: (value: ChangeEvent<HTMLInputElement>) => void;
  handleBirthCity: (value: string) => void;
  handleBirthRegion: (value: string) => void;
  handleBirthCountry: (value: string) => void;
  handleBirthMargin: (value: string) => void;
  handleIsInferred: (value: ChangeEvent<HTMLInputElement>) => void;
  continueAndReview: boolean;
  birthCity?: string;
  birthRegion?: string;
  birthCountry?: string;
  countryRef?: string;
  birthDate?: {
    day?: string;
    month?: string;
    year?: string;
    approximateYear?: string;
  };
  deathDate?: {
    day?: string;
    month?: string;
    year?: string;
  };
  minYear?: string;
}

export default function BirthDetails({
  countries,
  handleBirthDay,
  handleBirthMonth,
  handleBirthYear,
  handleBirthCity,
  handleBirthRegion,
  handleBirthCountry,
  handleBirthMargin,
  handleIsInferred,
  continueAndReview,
  birthCity,
  birthRegion,
  birthCountry,
  countryRef,
  birthDate = {
    day: '',
    month: '',
    year: '',
    approximateYear: '',
  },
  deathDate,
  minYear,
}: BirthDetailsProps) {
  const [listOfRegionsStates, setListOfRegionsState] = useState<
    ListRegionStatesTypes[] | undefined
  >(undefined);
  const [birthCountryState, setBirthCountryState] = useState('');
  const [birthRegionState, setBirthRegionState] = useState('');
  const [birthCityState, setBirthCityState] = useState('');
  const [pathsForLocation, setPathsForLocation] = useState<
    PathTypes[] | undefined
  >(undefined);
  const [subDivison, setTypeOfSubdivision] = useState('');
  const [showRegionState, setShowRegionState] = useState(false);
  const [searchTermBirthCity, setSearchTermBirthCity] = useState('');
  const [birthStatePath, setBirthRegionPath] = useState('');
  const [dateOfBirthState, setDateOfBirthState] = useState(birthDate);
  const [isLoadingAllRegionsBirth, setIsLoadingAllRegionsBirth] =
    useState(true);
  const [isLoadingAllCitiesBirth, setIsLoadingAllCitiesBirth] = useState(true);

  const onSelectPath = (selectedRegionState: string) => {
    setBirthCityState(selectedRegionState);
    setSearchTermBirthCity(selectedRegionState);
    handleBirthCity(selectedRegionState);
  };
  const handleBirthState = (state: string) => {
    if (state != birthRegion) {
      setBirthCityState('');
      handleBirthCity('');
    }
    setBirthRegionState(state);
    handleBirthRegion(state);
  };

  useEffect(() => {
    if (birthCountryState?.length > 0) {
      const newFoundCountry = (countries as LocationsTypes[])?.find(
        (countryWithRegion: LocationsTypes) =>
          countryWithRegion?.place?.toLowerCase() ===
          birthCountry?.toLowerCase()
      );

      const foundCountryDomainName = (countries as DataWorldChartTypes[])?.find(
        (country: DataWorldChartTypes) => country?.iso_3 === newFoundCountry?.id
      )?.api_public_domain_name as string;

      if ((newFoundCountry?.region_label as string)?.length > 0) {
        setTypeOfSubdivision(newFoundCountry?.region_label as string);
      }

      if (newFoundCountry?.id) {
        getLocation(newFoundCountry?.id, foundCountryDomainName)
          .then((res) => {
            setShowRegionState(true);
            setIsLoadingAllRegionsBirth(false);
            setListOfRegionsState(res);
          })
          .catch(() => {
            setIsLoadingAllRegionsBirth(false);
          });
      } else {
        setIsLoadingAllRegionsBirth(false);
      }
    } else {
      setIsLoadingAllRegionsBirth(false);
      setListOfRegionsState([]);
    }
  }, [birthCountry]);

  const styleForDisappearingDateOfBirh = useSpring({
    opacity: 1,
    display: 'flex',
    config: { tension: 900, friction: 50 },
  });

  useEffect(() => {
    if (birthCountry && birthCountry?.length > 0) {
      const foundCountry = (countries as LocationsTypes[])?.find(
        (countryWithRegions) =>
          countryWithRegions?.place?.toLowerCase() ===
          birthCountry?.toLowerCase()
      );

      const foundCountryDomainName = (countries as DataWorldChartTypes[])?.find(
        (country) => country?.iso_3 === foundCountry?.id
      )?.api_public_domain_name as string;

      if ((foundCountry?.region_label as string)?.length > 0) {
        setTypeOfSubdivision(foundCountry?.region_label as string);
      }

      if (foundCountry?.id) {
        getLocation(foundCountry?.id, foundCountryDomainName)
          .then((res) => {
            if (res) {
              setShowRegionState(true);
              setListOfRegionsState(res);
            }
          })
          .catch(() => console.log('error in get location'));
      }
    } else {
      setListOfRegionsState([]);
    }
  }, [birthCountry, birthCityState, countries]);

  useEffect(() => {
    const regionPath =
      (listOfRegionsStates?.find(
        (region) => region?.place?.toLowerCase() === birthRegion?.toLowerCase()
      )?.path as string) || '';
    if (
      listOfRegionsStates &&
      listOfRegionsStates?.length > 0 &&
      regionPath?.length > 0
    ) {
      const foundCountry = (countries as LocationsTypes[]).find(
        (countryWithRegions) =>
          countryWithRegions?.place?.toLowerCase() ===
          birthCountry?.toLowerCase()
      );

      const foundCountryDomainName = (countries as DataWorldChartTypes[])?.find(
        (country) => country?.iso_3 === foundCountry?.id
      )?.api_public_domain_name as string;

      if (foundCountry?.id && regionPath) {
        getLocationPath(
          foundCountry?.id as string,
          regionPath,
          foundCountryDomainName
        )
          .then((res) => {
            if (res) {
              setPathsForLocation(res);
            }
          })
          .catch((err) => {
            console.log(err);

            setPathsForLocation([]);
          });
      }
    }
  }, [birthCountry, listOfRegionsStates, birthRegion, countries]);

  useEffect(() => {
    const regionPath =
      (listOfRegionsStates &&
        (listOfRegionsStates?.find(
          (region) =>
            region?.place?.toLowerCase() === birthRegion?.toLowerCase()
        )?.path as string)) ||
      '';

    if (
      listOfRegionsStates &&
      listOfRegionsStates?.length > 0 &&
      regionPath?.length > 0
    ) {
      if (birthCountryState?.length > 0) {
        const newFoundCountry = (countries as LocationsTypes[]).find(
          (countryWithRegion: LocationsTypes) =>
            countryWithRegion?.place?.toLowerCase() ===
            birthCountry?.toLowerCase()
        );

        const foundCountryDomainName = (
          countries as DataWorldChartTypes[]
        )?.find(
          (country: DataWorldChartTypes) =>
            country?.iso_3 === newFoundCountry?.id
        )?.api_public_domain_name as string;

        setBirthRegionPath(regionPath);
        getLocationPath(
          newFoundCountry?.id as string,
          regionPath,
          foundCountryDomainName
        )
          .then((res) => {
            if (res) {
              setPathsForLocation(res);
              setIsLoadingAllCitiesBirth(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setPathsForLocation([]);
            setIsLoadingAllCitiesBirth(false);
          });
      } else {
        setPathsForLocation([]);
      }
    } else {
      if (!isLoadingAllRegionsBirth) {
        setIsLoadingAllCitiesBirth(false);
        setPathsForLocation([]);
      }
    }
  }, [
    birthCountry,
    birthCountryState,
    birthRegionState,
    listOfRegionsStates,
    birthStatePath,
    isLoadingAllRegionsBirth,
    birthRegion,
  ]);

  useEffect(() => {
    setBirthCityState('');
    setBirthRegionState('');
  }, [birthCountry]);

  useEffect(() => {
    setBirthCityState('');
  }, [birthRegion]);

  const handleDay = (e: ChangeEvent<HTMLInputElement>) => {
    setDateOfBirthState((prev) => ({ ...prev, day: e.target.value }));
    handleBirthDay(e);
  };

  const handleMonth = (month: string) => {
    if (month === 'Select') {
      setDateOfBirthState((prev) => ({ ...prev, month: '', day: '' }));
    } else {
      setDateOfBirthState((prev) => ({ ...prev, month: month }));
    }

    handleBirthMonth(
      monthOptions.find(
        (monthOption: { label: string; value: string }) =>
          monthOption.label === month
      )?.value || ''
    );
  };

  const handleYear = (e: ChangeEvent<HTMLInputElement>) => {
    setDateOfBirthState((prev) => ({ ...prev, year: e.target.value }));
    handleBirthYear(e);
  };

  const handleApproximateYear = (e: ChangeEvent<HTMLInputElement>) => {
    setDateOfBirthState((prev) => ({
      ...prev,
      approximateYear: e.target.value,
    }));
  };

  const handleCountry = (country: string) => {
    if (country != birthCountry) {
      setBirthRegionState('');
      handleBirthRegion('');
      setBirthCityState('');
      handleBirthCity('');
    }
    setBirthCountryState(country);
    handleBirthCountry(country);
  };

  return (
    <div className="flex w-full flex-col gap-2 desktop:w-[400px]">
      <div className="flex items-center">
        <h4 className="font-semibold">Birth Details</h4>{' '}
        <QuestionMarkIconCircle
          className="ml-2 flex size-5 items-center"
          id="birth-details"
          data-for="birth-details"
          data-tip={
            'Enter the year first, then the month and the day if you know them'
          }
        />
        <ReactTooltip
          className="tooltip-opacity"
          id="birth-details"
          type={'info'}
          multiline={true}
          effect={'solid'}
          place="top"
          backgroundColor={'#ECECEC'}
          textColor={'#454545'}
        />
      </div>
      <DayMonthYearFilter
        styles={styleForDisappearingDateOfBirh}
        containerClassName="flex items-center gap-2 w-full"
        onChangeDay={handleDay}
        onChangeApproxYear={(evt) => {
          handleBirthMargin(evt.target.value);
          handleIsInferred(evt);
        }}
        onChangeMonth={handleMonth}
        onChangeYear={handleYear}
        date={birthDate}
        monthDropdown={true}
        showIsInferredDrodpown={true}
        disabled={continueAndReview}
        showApproxYears={true}
        minYear={minYear}
      />
      <TailwindSelect
        disabled={continueAndReview}
        label="Country of birth"
        className="!m-0 !mt-1 !font-normal !text-slateBlack"
        options={(
          moveCountryToFirstPlace(
            countries as LocationsTypes[],
            countryRef
          ) as LocationsTypes[]
        )?.map((countryObj: LocationsTypes) => countryObj.place)}
        placeholder={'Select country'}
        value={birthCountry}
        onChange={handleCountry}
      />
      {(listOfRegionsStates && listOfRegionsStates?.length > 0) ||
      birthRegion ? (
        <TailwindSelect
          label="State of birth"
          className="!m-0 !font-normal !text-slateBlack"
          options={listOfRegionsStates?.map(
            (countryObj: ListRegionStatesTypes) => countryObj.place
          )}
          disabled={
            listOfRegionsStates?.length === 0 || continueAndReview
              ? true
              : false
          }
          placeholder={'Select state'}
          value={birthRegion}
          onChange={handleBirthState}
        />
      ) : (
        birthCountry && (
          <TailwindAlert
            className="w-full desktop:w-[400px]"
            message="Currently we are collecting data at the national level only."
          />
        )
      )}
      {((pathsForLocation && pathsForLocation?.length > 0) || birthCity) && (
        <TailwindSelect
          label="City of birth"
          className="!m-0 !font-normal !text-slateBlack"
          disabled={
            pathsForLocation?.length === 0 || continueAndReview ? true : false
          }
          options={pathsForLocation?.map((location) => location.place) || []}
          placeholder={'Select city'}
          value={birthCity}
          onChange={onSelectPath}
        />
      )}
    </div>
  );
}
